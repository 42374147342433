import { ScheduleDto, EntityTypeEnumSchema, EntityType } from 'goout-schemas';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import { useFormStore } from '~/store/form.store';
import { useGooutFetch } from 'goout-api';
import { SourceSchema } from 'goout-schemas/source.schema';
import { parseDate } from '~/utils/dateTimeFormat.util';

type ScheduleAttributesWithoutId = Omit<ScheduleDto['attributes'], 'id'>;

type NewSchedule = {
  type: EntityType;
  links: {
    self: string;
  };
  attributes: ScheduleAttributesWithoutId;
  relationships: ScheduleDto['relationships'];
};

export function usePublishSchedule() {
  const { category, venue, date, organizer, publish } = useFormStore();

  async function perform(eventId: string) {
    // Prepare schedules to iterate over later
    const schedulesData: NewSchedule[] = date.data.dates
      // Filter out incomplete dates
      .filter((date) => date.startDate !== '')
      // Map to ScheduleDtox
      .map((date) => {
        return {
          type: EntityTypeEnumSchema.enum.schedules,
          links: {
            self: '',
          },
          attributes: {
            state: 'unapproved',
            startAt: parseDate(date.startDate, date.startTime),
            endAt: parseDate(
              date.endDate || date.startDate,
              date.endTime || { hour: 23, minute: 55 }
            ),
            hasTime: date.startTime.hour !== '' && date.startTime.minute !== '',
            isPermanent: false,
            tags: category.data.tags,
            sourceUrls: [],
            source: SourceSchema.enum.creator,
            updatedAt: new Date().toISOString(),
            counts: {
              followers: 0,
              groupedSchedules: 0,
              groupedSchedulesInVenues: 0,
            },
            publishedAt: publish.data.publishAt || '',
            locales: {},
          },
          relationships: {
            contacts: {
              data: organizer.data.selectedItems.map((org) => {
                return {
                  id: org.id,
                  type: EntityTypeEnumSchema.enum.contacts,
                };
              }),
            },
            event: {
              data: {
                id: eventId,
                type: EntityTypeEnumSchema.enum.events,
              },
            },
            venue: {
              data: {
                id: venue.data.selectedItem.id,
                type: EntityTypeEnumSchema.enum.venues,
              },
            },
            parent: null,
            duplicateSchedules: {
              data: [],
            },
            parentInnerSchedules: {
              data: [],
            },
            innerSchedules: {
              data: [],
            },
          },
        };
      });

    const publishFetch = schedulesData.map((schedule) => {
      const { execute } = useGooutFetch<ScheduleDto>(ENDPOINT.SCHEDULES, {
        immediate: false,
      })
        .post(schedule)
        .json();
      return execute;
    });
    // Execute the functions to get the promises
    const publishPromises = publishFetch.map((execute) => execute());

    return await Promise.allSettled(publishPromises);
  }

  return { perform };
}
