import { useGooutFetch } from 'goout-api';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import qs from 'qs';

export function usePublishNow(scheduleId: string, date: Date) {
  const url = `${ENDPOINT.PUBLISH_NOW}?${qs.stringify({
    id: scheduleId,
    date: date.toISOString(),
  })}`;
  return useGooutFetch(url, {
    immediate: false,
  }).post();
}
