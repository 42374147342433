<script lang="ts" setup>
import Icon from 'goout-icons/Icon.vue';
import { useDropZone } from '@vueuse/core';
import { ref } from 'vue';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import { sendFileViaXHR } from 'goout-utils';
import { useFormStore } from '~/store/form.store';
import { EntityTypeEnumSchema, ImageDtoSchema, ImageDto } from 'goout-schemas';
import { Image } from '~/definitions/schemas/image/image.schema';
import { useToast } from '~/composables/useToast';
import { ToastProps } from '~/components/general/Toast.vue';

const formStore = useFormStore();
const dropZoneRef = ref<HTMLDivElement>();
const uploadInput = ref<HTMLInputElement>();
const { isOverDropZone } = useDropZone(dropZoneRef, onFileDrop);
const { showToast } = useToast();
const MAX_ALLOWED_NUMBER_OF_IMAGES = 6;
const toastPosition: ToastProps['position'] = 'top-right';

async function onFileDrop(newFiles: File[] | null) {
  if (!newFiles) return;
  if (
    formStore.image.data.images.length + newFiles.length >=
    MAX_ALLOWED_NUMBER_OF_IMAGES
  ) {
    showToast({
      message: 'images.error.too.many',
      position: toastPosition,
    });
    return;
  }

  const lastIndex = formStore.image.data.images.length ?? 0;
  // Set images here to trigger immediate image preview
  Array.from(newFiles).forEach((file, idx) => {
    formStore.image.data.images = [
      ...formStore.image.data.images,
      {
        file,
        progress: 0,
        id: String(lastIndex + idx),
      },
    ];
  });

  const notUploadedImages = formStore.image.data.images.filter(
    (image) => !image.url // not uploaded images do not have url yet
  );

  for (const file of notUploadedImages) {
    const response = await upload(file);
    const image = formStore.image.data.images.find((img) => img.id === file.id);
    if (image) image.controller = response.controller;
    await response.uploadPromise;
  }
}
/**
 * Uploads the file to the server
 * @param file
 */
async function upload(file: Partial<Image>) {
  if (!file) {
    return {
      controller: new AbortController(),
      uploadPromise: Promise.resolve(),
    };
  }

  const formData = new FormData();
  const controller = new AbortController();
  const { signal } = controller;

  formData.append('image', file.file);

  const uploadPromise = sendFileViaXHR(
    ENDPOINT.ENTITY_STORE_IMAGE_UPLOAD_BY_FILE,
    {
      data: formData,
      signal,
      onProgress: (progress: number) => {
        file.progress = progress;
      },
    }
  )
    .then((response: any) => {
      response.type = EntityTypeEnumSchema.enum.images; // In order to match the JSON:API schema we need to do this
      const imageDTO = ImageDtoSchema.parse(response); // But we still want to validate the rest of the response

      const image = convertImageDTOToImage(imageDTO);
      if (file?.id) formStore.image.data.images[file.id] = image;
    })
    .catch((error) => {
      formStore.image.data.images = formStore.image.data.images.filter(
        (img) => img.id !== file.id
      );

      if (error.name !== 'AbortError') {
        // Remove the pre-rendered image
        showToast({
          message: 'images.error.upload',
          position: toastPosition,
        });
      }
    });

  return {
    controller,
    uploadPromise,
  };
}

/**
 * Converts the response to the Image object
 * @param {Image} response
 */
function convertImageDTOToImage({ attributes, id }: ImageDto): Image {
  const { copyright, name, origin, url } = attributes;

  if (!url) {
    throw new Error('Image URL is missing');
  }

  const imageFormatted: Image = {
    id,
    url,
    copyright,
    name,
    origin,
  };

  return imageFormatted;
}

/**
 * This allow for reuploading the same image after deleting it
 * @param e
 */
async function handleInputFileChange(e: any) {
  if (!uploadInput.value) return;
  await onFileDrop(e.target.files);
  uploadInput.value.value = '';
}
</script>

<template>
  <div
    ref="dropZoneRef"
    class="bg-snow-white w-full flex flex-col justify-center items-center py-18"
    :class="{ 'bg-blue/15': isOverDropZone }"
  >
    <Icon
      name="Images"
      class="text-blue transition-colors !w-8 !h-8 group-hover:text-gray-dark"
    />
    <h2 class="text-dark font-semibold pt-4 text-md">
      {{ $t('image.drag.zone') }}
    </h2>

    <label for="upload" class="pt-2 text-sm underline cursor-pointer text-dark">
      {{ $t('image.upload.link') }}
    </label>
    <input
      id="upload"
      ref="uploadInput"
      :aria-label="$t('section.photo.label')"
      type="file"
      multiple
      accept="image/png, image/jpeg, image/bmp, .jpeg, .png, .jpg, .bmp, .webp"
      class="sr-only"
      @input="handleInputFileChange"
    />
  </div>
</template>
