<script setup lang="ts">
import TabContainer from '~/components/layout/TabContainer.vue';
import { useFormStore } from '~/store/form.store';
import { computed } from 'vue';
import { getCurrentLocale } from '~/i18n';
import PublishThumbnail from './PublishThumbnail.vue';
import { useI18n } from 'vue-i18n';
import { TabEnum, useTabsStore } from '~/store/tabs.store';
import { findLocaleWithValue } from '~/utils/findLocalewithValue.util';
import SummaryItem from './SummaryItem/SummaryItem.vue';
import SummaryItemLocalized from './SummaryItem/SummaryItemLocalized.vue';

const { general, image, venue, category, date, performer } = useFormStore();
const { tabs } = useTabsStore();
const { t } = useI18n();
const locale = getCurrentLocale();
// Format the data for the summary
const name = computed(
  () => findLocaleWithValue(general.data?.name, locale) || '-'
);

const categories = computed(() =>
  category.data?.selectedCategories
    ?.map((cat) => t(`general.tags.${cat}`))
    .join(', ')
);
const tags = computed(() =>
  category.data?.tags?.map((tag) => t(`general.tags.${tag}`)).join(', ')
);
const hidePerformersItem = computed(() => !tabs.has(TabEnum.Performer));
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.publish.title')"
    :perex="$t('wizard.tabs.publish.perex')"
    :fluid="true"
  >
    <div class="flex justify-center gap-x-8">
      <PublishThumbnail
        :name="name"
        :image="image.data.images[0]"
        :primary-category="category.data?.primaryCategory"
        :venue="venue.data.selectedItem"
        :date="date.data.dates[0]"
      />
      <div class="flex flex-col">
        <h3 class="text-lg font-semibold pb-2 text-dark">
          {{ $t('tabs.publish.summary') }}
        </h3>
        <div class="gap-y-4 py-4">
          <SummaryItemLocalized
            :label="$t('wizard.tabs.publish.name')"
            :localized-text="general.data.name"
          />
          <SummaryItemLocalized
            :label="$t('wizard.tabs.publish.description')"
            :localized-text="general.data.description"
            is-html
          />
          <SummaryItem
            :label="$t('wizard.tabs.publish.web')"
            :text="general.data?.web || '-'"
          />
          <SummaryItem
            :label="$t('wizard.tabs.publish.categories')"
            :text="categories || '-'"
          />
          <SummaryItem
            :label="$t('wizard.tabs.publish.tags')"
            :text="tags || '-'"
          />
          <SummaryItem
            :label="$t('wizard.tabs.publish.venue')"
            :text="venue.data.selectedItem?.title || '-'"
          />
          <SummaryItem
            v-if="!hidePerformersItem"
            :label="$t('wizard.tabs.publish.performers')"
            :text="
              performer.data.selectedItems
                .map((performer) => performer.title)
                .join(', ') || '-'
            "
          />
        </div>
      </div>
    </div>
  </TabContainer>
</template>
