<script lang="ts" setup>
import { ref } from 'vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { SUPPORTED_LOCALES } from '~/i18n';
import { Locale, useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';

const isOpen = ref(false);
const buttonRef = ref();
const i18n = useI18n();

const handleLocaleChange = (selectedOption: Locale) => {
  i18n.locale.value = selectedOption;
};

const isSelectedLocale = (locale: Locale) => i18n.locale.value === locale;

onClickOutside(buttonRef, () => {
  isOpen.value = false;
});
</script>

<template>
  <div class="relative">
    <div ref="buttonRef" tabindex="0" @click="isOpen = !isOpen">
      <Button
        v-tracking="{
          category: 'language_switch',
          label: 'globe_icon',
        }"
        class="w-12 h-12 !p-2 !text-dark border-gray/24"
        :class="{
          'border-[2px] border-blue ': isOpen,
        }"
        variant="border"
        size="sm"
        icon="Globe"
        round
        :aria-expanded="isOpen"
        aria-haspopup="true"
        aria-controls="locale-dropdown"
        role="button"
      />
    </div>
    <div
      v-if="isOpen"
      id="locale-dropdown"
      class="dropdown bg-white py-2 absolute w-[120px] right-0 mt-12 top-2 z-10 cursor-default rounded-md shadow-lg"
      role="menu"
    >
      <ul class="list-none">
        <li
          v-for="locale in SUPPORTED_LOCALES"
          :key="`locale-${locale}`"
          class="text-sm group text-dark cursor-pointer flex items-center p-3 pl-5 text-light-blue"
          :class="{ 'text-blue': isSelectedLocale(locale) }"
          role="menuitem"
          tabindex="0"
          @click="handleLocaleChange(locale)"
        >
          <span class="group-hover:text-blue">
            {{ $t(`language.${locale}`) }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
}
</style>
