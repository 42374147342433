<script setup lang="ts">
import { computed, useSlots } from 'vue';
import Icon, { IconName } from 'goout-icons/Icon.vue';
import { ButtonVariant } from '../types';

interface Props {
  variant: ButtonVariant;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  round?: boolean;
  href?: string;
  disabled?: boolean;
  class?: string | object | (string | object)[]; // can be used to extend/override default styles
  icon?: IconName;
  iconClass?: string;
  iconPosition?: 'leading' | 'trailing';
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  size: 'base',
  iconPosition: 'leading',
});

const variantStyles: Record<ButtonVariant, string> = {
  primary: 'bg-blue text-white hover:bg-blue-hover disabled:opacity-75 ' +
           'disabled:bg-snow-white disabled:text-gray/40 disabled:hover:bg-snow-white ' +
           'disabled:hover:text-gray/40',

  'primary-disabled-blue': 'bg-blue text-white hover:bg-blue-hover disabled:opacity-32 ' +
                           'disabled:text-white',

  secondary: 'bg-blue-light text-blue hover:bg-blue-light-hover disabled:opacity-75 ' +
             'disabled:bg-snow-white disabled:hover:bg-snow-white disabled:text-gray/40',

  tertiary: 'text-gray bg-snow-white hover:text-gray-dark disabled:opacity-75 ' +
            'disabled:bg-snow-white disabled:hover:bg-snow-white disabled:text-gray/40',

  border: 'text-dark bg-white border-1 border-gray/40',

  text: 'bg-transparent text-gray hover:text-dark disabled:text-gray/40 ' +
        'disabled:hover:text-gray/40',

  add: 'bg-transparent text-blue hover:text-blue-hover disabled:text-gray/40',

  'primary-dark': 'bg-dark text-white hover:bg-dark/75 disabled:bg-snow-white ' +
                  'disabled:hover:bg-snow-white',

  'text-dark': 'text-dark disabled:text-gray-light',

  'border-snow-white': 'border-1 border-snow-white',

  'text-blue': 'text-blue hover:text-blue-hover disabled:text-blue/40',
};

const sizes = {
  xs: 'py-1 px-3',
  sm: 'py-3 px-5',
  base: 'py-4 px-9',
  lg: 'py-6 px-10 min-w-44',
  xl: 'py-8 px-20',
};

const slots = useSlots();
const componentType = computed(() => (props.href ? 'a' : 'button'));
</script>

<template>
  <component
    :is="componentType"
    class="cursor-pointer font-medium text-sm outline-0 transition-colors duration-150 items-center btn inline-flex justify-center disabled:cursor-default"
    :class="[
      variantStyles[props.variant],
      sizes[props.size],
      { 'rounded-full': round },
      props.class,
    ]"
    w:focus-visible="ring-offset-2 ring-2 ring-blue-hover ring-opacity-75"
    type="button"
    :disabled="disabled"
    v-bind="{
      ...(href && { href }),
      ...$attrs
    }"
  >
    <Icon
      v-if="icon && iconPosition === 'leading'"
      :name="icon"
      :class="[{ 'mr-2': slots.default }, iconClass]"
      aria-hidden
    />
    <slot />
    <Icon
      v-if="icon && iconPosition === 'trailing'"
      :name="icon"
      :class="[{ 'mr-2': slots.default }, iconClass]"
      aria-hidden
    />
  </component>
</template>

<style lang="scss" scoped>
/* Focus fallback for older browsers */
@supports not selector(:focus-visible) {
  .btn:focus {
    @apply ring-blue-hover ring-offset-2 ring-2 ring-opacity-75;
  }
}
</style>
