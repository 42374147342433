import z from 'zod';

export const TagEnumSchema = z.enum([
  'event',
  'deleted_01',
  'performer',
  'venue',
  'schedule',
  'concerts',
  'play',
  'exhibitions',
  'film',
  'clubbing',
  'festivals',
  'gastronomy',
  'recommended_in_category',
  'template',
  'recommended',
  'venue_top',
  'for_children',
  'club',
  'theatre',
  'gallery',
  'in_city',
  'talk',
  'cinema',
  'museum',
  'sport',
  'concert_hall',
  'cultural_centre',
  'bar',
  'cafe',
  'restaurant',
  'online',
  'pub',
  'tea_room',
  'public_space',
  'sight',
  'sport_venue',
  'brewery',
  'indoor_space',
  'zoo_and_botanic_gardens',
  'musicians',
  'actors',
  'artists',
  'theatre_groups',
  'orchestras',
  'choirs',
  'bands',
  'male_singers',
  'female_singers',
  'actresses',
  'djs',
  'photographers',
  'performers_top',
  'public_person',
  'library',
  'english_friendly',
  'comedian',
  'viticulture',
  'partner_internal',
  'film_war',
  'exhibitions_paintings',
  'projection',
  'punk',
  'rock',
  'metal',
  'hip_hop',
  'pop',
  'drama',
  'comedy',
  'film_horror',
  'film_thriller',
  'drawings',
  'conference',
  'indie',
  'recording',
  'play_opera',
  'charity',
  'gallery_sculpture',
  'gallery_design',
  'gallery_classical_art',
  'cinema_multiplex',
  'rappers',
  'sold_out',
  'schedule_all_time',
  'schedule_today',
  'schedule_tomorrow',
  'schedule_weekend',
  'schedule_week',
  'filter_all',
  'for_me',
  'popular',
  'film_historic',
  'film_animated',
  'film_romantic',
  'film_sci_fi',
  'documentary',
  'fairy_tale',
  'musical',
  'film_action',
  'liked',
  'dance',
  'r_n_b',
  'jazz',
  'film_student',
  'schedule_next_week',
  'folk',
  'country',
  'augmented_reality',
  'classical',
  'eco',
  'reggae',
  'schedule_month',
  'schedule_year',
  'schedule_next_year',
  'schedule_calendar',
  'film_short',
  'play_ballet',
  'play_puppets',
  'film_for_children',
  'play_pantomime',
  'film_road_movie',
  'film_recording',
  'film_music',
  'play_black',
  'exhibitions_sculptures',
  'exhibitions_photography',
  'exhibitions_fashion',
  'play_literature',
  'k_pop',
  'post_punk',
  'trap',
  'ambient',
  'ska',
  'new_age',
  'gallery_photography',
  'chanson',
  'play_musical',
  'play_melodrama',
  'play_performance',
  'play_tragedy',
  'play_one_person_show',
  'concerts_release_party',
  'concerts_industrial',
  'clubbing_latin',
  'clubbing_indie',
  'in_city_quiz',
  'theatre_opera',
  'sale_on_venue',
  'exhibitions_street_art',
  'exhibition_finissage',
  'exhibition_comics',
  'exhibition_typography',
  'architecture',
  'handicraft',
  'cinema_open_air',
  'exhibition_sound_art',
  'exhibition_objects',
  'exhibition_folk_art',
  'exhibition_site_specific',
  'in_city_talk_show',
  'in_city_webinar',
  'in_city_ball',
  'in_city_swap',
  'in_city_well_being',
  'in_city_auction',
  'in_city_poetry',
  'in_city_course',
  'museum_technical',
  'museum_observatory',
  'cinema_art',
  'in_city_fun_and_experience',
  'in_city_burlesque',
  'film_psychological',
  'film_erotic',
  'film_dance',
  'festivals_multigenre',
  'festivals_music',
  'film_disaster',
  'film_exploitation',
  'guestlist',
  'deleted_178',
  'cultural_centre_cultural_houses',
  'cultural_centre_national_centres',
  'deleted_181',
  'deleted_182',
  'restaurant_italian',
  'restaurant_czech',
  'do_not_show',
  'museum_historical',
  'deleted_187',
  'museum_natural_science',
  'deleted_189',
  'castle',
  'deleted_191',
  'deleted_192',
  'deleted_193',
  'exhibitions_contemporary',
  'film_adventure',
  'film_fantasy',
  'film_crime',
  'film_family',
  'film_biography',
  'gallery_modern_art',
  'film_mystery',
  'cinema_single_auditorium',
  'deleted_203',
  'sight_palace',
  'deleted_205',
  'exhibitions_design',
  'sight_summer_house',
  'deleted_208',
  'deleted_209',
  'deleted_210',
  'deleted_211',
  'deleted_212',
  'electronic',
  'theatre_alternative',
  'deleted_215',
  'deleted_216',
  'deleted_217',
  'hardcore',
  'deleted_219',
  'exhibitions_graphics',
  'exhibitions_classic',
  'gallery_contemporary_art',
  'deleted_223',
  'theatre_music',
  'deleted_225',
  'deleted_226',
  'deleted_227',
  'deleted_228',
  'deleted_229',
  'show',
  'play_dance',
  'theatre_dance',
  'workshop',
  'play_slam_poetry',
  'deleted_235',
  'film_new',
  'film_czech',
  'play_magic',
  'discussion',
  'exhibitions_history',
  'local',
  'foreign',
  'performing',
  'new',
  'in_city_educational',
  'in_city_outdoor',
  'deleted_247',
  'in_city_art_and_design',
  'in_city_history',
  'in_city_anniversary_party',
  'play_physical',
  'exhibitions_permanent',
  'in_city_science_tech',
  'exhibitions_new_media',
  'exhibitions_installations',
  'exhibitions_performance',
  'exhibitions_illustrations',
  'premier',
  'dernier',
  'cancelled',
  'schedule_3d',
  'sound_dubbed',
  'sound_original',
  'free',
  'exhibitions_vernisage',
  'newly_announced',
  'cafe_literary',
  'organizer',
  'deleted_269',
  'deleted_270',
  'deleted_271',
  'deleted_272',
  'deleted_273',
  'deleted_274',
  'voluntary',
  'in_city_game',
  'deleted_277',
  'deleted_278',
  'deleted_279',
  'deleted_280',
  'deleted_281',
  'in_city_city_markets',
  'in_city_city_fashion',
  'play_audiowalk',
  'public_rehersal',
  'deleted_286',
  'order_by_start',
  'order_by_friends',
  'postponed_indefinitely',
  'play_alternative',
  'play_new_circus',
  'play_cabaret',
  'play_scenic_reading',
  'play_stand_up_comedy',
  'play_for_schools',
  'play_improvisation',
  'exhibitions_monographic',
  'experimental',
  'film_noir',
  'film_western',
  'film_biographical',
  'festivals_theatre',
  'festivals_films',
  'festivals_food',
  'festivals_art_design',
  'festivals_other',
  'play_immersive',
  'exhibitions_science_technology',
  'in_city_sport',
  'in_city_fair',
  'in_city_literature',
  'in_city_release',
  'in_city_walk',
  'in_city_guided_tour',
  'blues',
  'electro_swing',
  'gospel',
  'soul',
  'swing',
  'trip_hop',
  'noise',
  'funk',
  'folklore',
  'latin_american',
  'play_sci_fi',
  'party_breakbeat',
  'party_dancehall',
  'party_disco',
  'party_dub',
  'party_dubstep',
  'party_drum_and_bass',
  'party_edm',
  'deleted_333',
  'deleted_334',
  'party_house',
  'deleted_336',
  'party_grime',
  'party_hardstyle',
  'party_oldies',
  'party_techno',
  'party_trance',
  'party_trap',
  'gastronomy_dinner',
  'gastronomy_tasting',
  'gastronomy_vege_vegan',
  'deleted_346',
  'gastronomy_market',
  'gastronomy_courses',
  'deleted_349',
  'for_children_educational',
  'for_children_fun_experience',
  'rap',
  'gastronomy_brunch',
  'tech_house',
  'uk_bass',
  'electro',
  'in_city_meeting',
  'in_city_networking',
  'in_city_travels',
  'exhibitions_video',
  'exhibitions_mixed_media',
  'storytelling',
  'open_decks',
  'jam_session',
  'sdh'
]);

export type Tag = z.infer<typeof TagEnumSchema>;