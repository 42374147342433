<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  TransitionRoot,
} from '@headlessui/vue';
import Button from 'goout-ui-kit/button/Button.vue';
import Spinner from './Spinner.vue';
import { ref } from 'vue';

type Overlay = {
  color: 'snow-white' | 'gray-dark' | 'transparent';
};

export interface Props {
  onSubmit?: () => void;
  onClose?: () => void;
  noCloseButton?: boolean;
  disabled?: boolean;
  width?: 'sm' | 'md' | 'lg' | 'xl';
  overlayColor?: Overlay['color'];
  blur?: boolean;
  rounded?: 'sm' | 'md' | 'lg' | 'xl' | '3xl';
  isLoading?: boolean;
  confirm?: boolean;
  open?: boolean;
  divider?: boolean;
}

defineOptions({
  inheritAttrs: false,
});

const {
  rounded = '3xl',
  width = 'md',
  blur = true,
  overlayColor = 'gray-dark',
  disabled = false,
  onClose,
  open = true,
  divider = true,
} = defineProps<Props>();

// You can use this template ref to autofocus
const initialFocus = ref();

const widthMap = {
  sm: 'sm:max-w-sm',
  md: 'w-max-6/12 3xl:w-max-3/12',
  lg: 'w-max-8/12 3xl:w-max-3/12',
  xl: 'sm:max-w-screen-md',
};

const roundedMap = {
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '3xl': 'rounded-3xl',
};

const overlayMap = {
  'snow-white': 'bg-snow-white/75',
  'gray-dark': 'bg-gray-dark/88',
};
</script>

<template>
  <Teleport to="#modal">
    <TransitionRoot
      appear
      as="template"
      :show="true"
      enter="transition-opacity ease-linear duration-800"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity ease-linear duration-800"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <Dialog
        as="div"
        class="z-40 relative"
        :open
        v-bind="$attrs"
        :initial-focus
        @close="onClose"
      >
        <div
          class="fixed inset-0"
          :class="[
            overlayMap[overlayColor],
            { 'backdrop-filter backdrop-blur-sm': blur },
          ]"
          aria-hidden="true"
        />
        <div class="inset-0 z-40 fixed overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center md:p-0 sm:items-center"
          >
            <DialogPanel
              class="z-50 relative bg-white p-10"
              :class="[roundedMap[rounded], widthMap[width]]"
            >
              <!-- Close Button -->
              <div class="absolute top-0 right-0 p-4">
                <Button
                  v-if="!noCloseButton"
                  variant="text"
                  icon="Close"
                  class="!p-0"
                  icon-class="!p-[2px] text-dark"
                  @click="onClose"
                />
              </div>
              <DialogTitle class="text-[20px] font-semibold text-left">
                <slot name="title" />
              </DialogTitle>

              <DialogDescription
                class="text-gray-dark pb-5 pt-4 font-medium text-sm leading-[21px]"
              >
                <slot name="description" />
              </DialogDescription>

              <slot name="content" />

              <div
                v-if="divider"
                class="divider h-[1px] w-full bg-snow-white my-4"
              />
              <slot name="buttons">
                <Spinner v-if="isLoading" class="mx-auto h-14 mt-8 text-blue" />
                <div v-else class="flex justify-center gap-x-2 pt-5">
                  <Button
                    variant="border"
                    class="w-full border-1 !border-gray-lighter/24"
                    size="lg"
                    round
                    @click="onClose"
                  >
                    <slot name="close">
                      <span>{{ $t('btn.close') }}</span>
                    </slot>
                  </Button>

                  <Button
                    ref="initialFocus"
                    variant="primary"
                    size="lg"
                    class="w-full"
                    :disabled
                    round
                    @click="onSubmit"
                  >
                    <slot name="confirm" />
                  </Button>
                </div>
              </slot>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>
