import z from 'zod';
import { StateEnumSchema } from '../state.schema';
import { TagEnumSchema } from '../tag.schema';
import { CurrencyEnumSchema } from '../currency.schema';
import {
  RelationshipToMany,
  RelationshipToOne,
} from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { Prettify } from '../jsonApi/utils/helpers';
import { Venue, VenueSchema } from '../venue/venue.schema';
import {
  SaleDtoAttributes,
  SaleDtoAttributesSchema,
} from '../sale/sale.schema';
import { ContactDtoAttributes, ContactSchema } from '../contact/contact.schema';
import { Event, EventSchema } from '../event/event.schema';
import { SourceSchema } from '../source.schema';

export const TicketingStateEnum = z.enum([
  'active',
  'sold_out',
  'hidden',
  'scheduled',
  'ended',
  'cancelled',
  'free',
  'voluntary',
  'on_venue',
  'unknown',
  'inner_event_sales',
  'external',
]);

export const ScheduleCounts = z
  .object({
    followers: z.number().int().nullish(),
    groupedSchedules: z.number().int().nullish(),
    groupedSchedulesInVenues: z.number().int().nullish(),
  })
  .partial();

const ScheduleDtoLocaleSchema = z.object({
  stage: z.string(),
  siteUrl: z.string(),
  sourceUrl: z.string(),
});

export const publishedAtSchema = z.date().or(z.string()).nullish();

export const ScheduleDtoAttributesSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  state: StateEnumSchema.nullish(),
  startAt: z.date().or(z.string()),
  endAt: z.date().or(z.string()),
  hasTime: z.boolean().nullish(),
  hasTimeEnd: z.boolean().nullish(),
  doorsTimeAt: z.string().nullish(),
  announcedAt: z.string().nullish(),
  publishedAt: publishedAtSchema,
  isPermanent: z.boolean().nullish(),
  externalTicketsUrl: z.string().nullish(),
  externalStreamUrl: z.string().nullish(),
  tags: z.array(TagEnumSchema).nullish(),
  sourceUrls: z.array(z.string()).nullish(),
  source: SourceSchema.nullish(),
  ticketingState: TicketingStateEnum.nullish(),
  pricing: z.string().nullish(),
  updatedAt: z.string().nullish(),
  counts: ScheduleCounts.nullish(),
  currency: CurrencyEnumSchema.nullish(),
  locales: z
    .record(
      z
        .object({ deprecated_fi: ScheduleDtoLocaleSchema })
        .partial()
        .passthrough()
    )
    .nullish(),
});

export const ScheduleDtoRelationshipsSchema = z.object({
  contacts: RelationshipToMany('contacts'),
  sale: RelationshipToOne('sales'),
  venue: RelationshipToOne('venues'),
  event: RelationshipToOne('events'),
  parent: RelationshipToOne('schedules'),
  parentInnerSchedules: RelationshipToMany('schedules'),
  innerSchedules: RelationshipToMany('schedules'),
  duplicateSchedules: RelationshipToMany('schedules'),
});

export const ScheduleDtoSchema = z.object({
  attributes: ScheduleDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.schedules),
  relationships: ScheduleDtoRelationshipsSchema,
});

// [cities,deals,discounts,events,images,performers,sales,venues,contacts,videos,parents]
export const ScheduleIncludesSchema = [
  EntityTypeEnumSchema.Values.cities,
  EntityTypeEnumSchema.Values.deals,
  EntityTypeEnumSchema.Values.discounts,
  EntityTypeEnumSchema.Values.events,
  EntityTypeEnumSchema.Values.images,
  EntityTypeEnumSchema.Values.performers,
  EntityTypeEnumSchema.Values.sales,
  EntityTypeEnumSchema.Values.venues,
  EntityTypeEnumSchema.Values.contacts,
  EntityTypeEnumSchema.Values.videos,
  EntityTypeEnumSchema.Values.parents,
];

export const ScheduleSchema = z
  .object({
    id: z.string(),
    type: z.literal(EntityTypeEnumSchema.Values.schedules),
  })
  .merge(ScheduleDtoAttributesSchema)
  .merge(
    z.object({
      contacts: z.lazy(() => z.array(ContactSchema)),
      sale: SaleDtoAttributesSchema.nullish(),
      venue: VenueSchema.nullish(),
      event: EventSchema.nullish(),
      parent: z.lazy(() => z.union([ScheduleSchema, z.null()])),
      parentInnerSchedules: z.lazy(() => z.array(ScheduleSchema)),
      innerSchedules: z.lazy(() => z.array(ScheduleSchema)),
      duplicateSchedules: z.lazy(() => z.array(ScheduleSchema)),
    })
  )
  .partial() as z.ZodSchema<Schedule>; //This is needed in order to use the Schedule type in the store if not, the type is not inferred correctly and is any

export type ScheduleDto = z.infer<typeof ScheduleDtoSchema>;
export type ScheduleDtoAttributes = z.infer<typeof ScheduleDtoAttributesSchema>;
export type ScheduleDtoRelationships = z.infer<
  typeof ScheduleDtoRelationshipsSchema
>;
export type ScheduleDtoLocale = z.infer<typeof ScheduleDtoLocaleSchema>;

export type ScheduleRelationships = {
  contacts: ContactDtoAttributes[];
  sale: SaleDtoAttributes;
  venue: Venue;
  event: Event;
  parent: Schedule;
  parentInnerSchedules: Schedule[];
  innerSchedules: Schedule[];
  duplicateSchedules: Schedule[];
};

export type ScheduleIncludes = (typeof ScheduleIncludesSchema)[number];
export type Schedule = Prettify<
  {
    type: typeof EntityTypeEnumSchema.Values.schedules;
  } & ScheduleDtoAttributes &
    ScheduleRelationships
>;
