<script lang="ts" setup>
import Modal from '~/components/general/Modal.vue';
import { ref } from 'vue';
import Datepicker from '../tabs/DateTab/Datepicker.vue';
import Timepicker from '../tabs/DateTab/Timepicker.vue';
import { useFormStore } from '~/store/form.store';
import { parseDate } from '~/utils';

const open = defineModel<boolean>();
const date = ref<string>('');
const time = ref({
  hour: '' as unknown as number,
  minute: '' as unknown as number,
});

const formStore = useFormStore();

const emit = defineEmits(['submit']);

const handleClose = () => {
  open.value = false;
};

const handleSubmit = () => {
  formStore.publish.data.publishAt = parseDate(date.value, time.value);
  emit('submit');
  open.value = false;
};
</script>

<template>
  <Modal
    :open="open"
    :on-close="handleClose"
    class="px-10"
    width="md"
    @submit="handleSubmit"
    @on-close="handleClose"
  >
    <template #title>
      <div class="flex gap-2">
        <span>{{ $t('modal.schedule.publish.title') }}</span>
      </div>
    </template>
    <template #confirm>
      <span>{{ $t('modal.schedule.publish.confirm') }}</span>
    </template>

    <template #content>
      <div class="flex items-center gap-x-4 pb-3 justify-center w-full">
        <Datepicker
          v-model="date"
          class="flex-1"
          :label="$t('modal.schedule.publish.date')"
          disable-past
        />
        <Timepicker
          v-model="time"
          :label="$t('modal.schedule.publish.time')"
          class="w-min-32 xl:w-min-84"
        />
      </div>
    </template>
  </Modal>
</template>
