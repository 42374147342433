<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import VueDatepicker from 'vue3-datepicker';
import { cs, de, pl, sk, enGB } from 'date-fns/locale';

import { parseISO, format } from 'date-fns';
import { getCurrentLocale } from '~/i18n';

interface Props {
  label: string;
  error?: string;
  lowerLimit?: Date | string;
  upperLimit?: Date | string;
  startingView?: 'time' | 'day' | 'month' | 'year';
}

const props = withDefaults(defineProps<Props>(), {
  startingView: 'day',
  error: '',
  lowerLimit: undefined,
  upperLimit: undefined,
});

const datepicker = ref<any>();
const isFocused = ref(false);

const dateValue = defineModel<Date | string>({
  required: true,
  get(value) {
    return value ? parseISO(value.toString()) : undefined;
  },
  set(value) {
    return value ? format(value as Date, 'yyyy-MM-dd') : undefined;
  },
});

const lowerLimitComputed = computed(() =>
  props.lowerLimit ? parseISO(props.lowerLimit as unknown as string) : undefined
);

const upperLimit = computed(() =>
  props.upperLimit ? parseISO(props.upperLimit as unknown as string) : undefined
);

function useDateFormat(locale: string) {
  switch (locale) {
    case 'en':
      return 'dd/MM/yyyy';
    default:
      return 'dd.MM.yyyy'; // cs|sk
  }
}

const dateFormat = useDateFormat(getCurrentLocale());

const toggleFocus = () => {
  isFocused.value = !isFocused.value;
};

const handleKeydown = (event) => {
  const key = event.key;
  if (
    [
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Backspace',
      'Enter',
      'Space',
    ].includes(key)
  ) {
    return true;
  }

  const separator = ['.', '/'].find((char) => dateFormat.includes(char));
  if (!isNaN(Number(key)) || key === separator) {
    return true;
  }

  event.preventDefault();
  return false;
};

const locales = {
  en: enGB,
  cs,
  de,
  pl,
  sk,
};

const currentLocale = getCurrentLocale();

function onClose() {
  nextTick(() => {
    datepicker.value.$el.querySelector('input').blur();
  });
}
</script>

<template>
  <div class="relative">
    <div v-if="isFocused" class="mobile-overlay"></div>
    <VueDatepicker
      ref="datepicker"
      v-model="(dateValue as Date)"
      :locale="locales[currentLocale]"
      :starting-view="startingView"
      :input-format="dateFormat"
      clearable
      :lower-limit="lowerLimitComputed || new Date()"
      :upper-limit="upperLimit"
      :allow-outside-interval="true"
      :text-input-options="{
        enterSubmit: true,
        tabSubmit: true,
      }"
      @focus="toggleFocus"
      @blur="toggleFocus"
      @keydown="handleKeydown"
      @closed="onClose"
    />
    <label :class="{ 'has-value': dateValue !== undefined || isFocused }">{{
      label
    }}</label>
  </div>
</template>

<style lang="scss" scoped>
.mobile-overlay {
  @apply <md:bg-snow-white <md:bg-opacity-75 <md:inset-0 <md:transition-opacity <md:fixed <md:backdrop-filter <md:backdrop-blur-sm <md:z-30;
}

::v-deep() {
  button.current.v3dp__element__button__day span {
    @apply text-blue;
  }

  label {
    @apply text-gray transform transition-all top-1/2 -translate-y-1/2 left-4 absolute pointer-events-none text-sm;
  }

  .has-value {
    transform: translateY(-110%);
    font-size: 11px;
  }

  .v3dp__clearable {
    display: none;
  }

  .v3dp__datepicker {
    --vdp-current-date-font-weight: medium;
    input {
      @apply bg-snow-white text-sm outline-none text-dark w-full px-4 pt-7 pb-4 transition-all font-medium;

      &:focus {
        @apply bg-gray/16 shadow-border shadow-blue;

        &:disabled {
          @apply bg-snow-white;
        }
      }

      &:hover {
        @apply bg-gray/20;

        &:disabled {
          @apply bg-snow-white;
        }
      }

      &.error {
        @apply bg-red/16 shadow-red;

        &:focus {
          @apply bg-red/18;
        }

        &:hover {
          @apply bg-red/20;
        }
      }

      &::placeholder {
        @apply text-xs;
      }
    }
  }

  .v3dp__heading {
    .v3dp__heading__center {
      @apply flex font-sans font-medium text-sm  p-0 transition-colors capitalize justify-center items-center hover:bg-transparent hover:text-blue;
    }

    .v3dp__heading__button {
      background: #f5f5f5;
      border-radius: 100%;
      margin: 0 4px;
      &:not(:disabled):hover {
        background: transparent;

        svg path {
          @apply stroke-blue;
        }
      }

      svg {
        @apply h-2 w-2;

        path {
          @apply stroke-black;
          stroke-width: 1.5px;
        }
      }
    }
  }

  .v3dp__datepicker .v3dp__popout {
    //@apply rounded-none shadow w-84  absolute z-30 fixed text-dark;
    @apply rounded-none shadow w-full py-8 px-8 bottom-0 left-0 z-50  sm:w-92 sm:p-5 sm:absolute sm:bottom-auto text-dark;
  }

  .v3dp__element__button__day {
    font-weight: 500 !important;
  }

  .v3dp__body {
    padding-top: 12px;
    .v3dp__subheading {
      text-transform: capitalize;
      padding-bottom: 8px;
    }
    .v3dp__divider {
      display: none;
    }

    .v3dp__elements button {
      padding: 0;
      display: flex;
      justify-content: center;
    }

    .v3dp__elements button:disabled {
      span {
        @apply text-gray/50;
      }
    }

    --vdp-current-date-font-weight: normal;
    --elem-current-outline-color: white;
    --vdp-selected-bg-color: black;
    --elem-hover-bg-color: black;
    --elem-selected-bg-color: black;

    .v3dp__elements button:not(:disabled):not(.selected):hover {
      span {
        @apply text-blue bg-blue/15;
      }
    }
    .v3dp__elements button.selected {
      span {
        @apply bg-black text-white font-semibold;
      }
    }
    .v3dp__elements button span {
      @apply rounded-full flex font-sans font-medium h-11 text-sm w-11 justify-center items-center;
    }
  }
}
</style>
