import { ComboboxSingleItem } from '~/components/general/ComboboxSingle.vue';
import { LocalizedInput } from '~/types/form.types';
import { EventCategory, Tag } from 'goout-data';
import { Image } from '~/definitions/schemas/image/image.schema';

export const initGeneralState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    name: createLocaleStructure(),
    description: createLocaleStructure(),
    web: '',
  },
});

export const initCategoryState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    selectedCategories: [] as EventCategory[],
    primaryCategory: '' as EventCategory,
    isConfirmed: false,
    tags: [] as Tag[],
  },
});

export const initVenueState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    selectedItem: {} as ComboboxSingleItem,
  },
});

export const initPerformerState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    selectedItems: [] as ComboboxSingleItem[],
  },
});

export const initImageState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    images: [] as Image[],
  },
});

export const initMetadataFilmState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    selectedItem: {} as ComboboxSingleItem,
    values: {
      originalName: '',
      countryIsos: [],
      minorPerformers: [],
      director: '',
      released: '',
      length: '',
      tags: [],
    },
  },
});

export const initMetadataPlayState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    values: {
      director: '',
      premiereDate: '',
      name: '',
      actors: '',
      author: '',
      length: '' as unknown as number,
    },
  },
});

export const initDateState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    dates: [
      {
        endDate: '',
        startDate: '',
        endTime: {
          hour: '' as unknown as number,
          minute: '' as unknown as number,
        },
        startTime: {
          hour: '' as unknown as number,
          minute: '' as unknown as number,
        },
      },
    ],
  },
});

export const initOrganizerState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    selectedItems: [] as ComboboxSingleItem[],
  },
});

export const initPublishState = () => ({
  isValid: false,
  isVisited: false,
  isDirty: false,
  data: {
    publishAt: '',
  },
});

const initStates = {
  general: initGeneralState(),
  category: initCategoryState(),
  venue: initVenueState(),
  performer: initPerformerState(),
  image: initImageState(),
  metadata_film: initMetadataFilmState(),
  metadata_play: initMetadataPlayState(),
  date: initDateState(),
  organizer: initOrganizerState(),
  publish: initPublishState(),
};

export { initStates };

/**
 * Used to create a structure for multi-language fields
 * @returns { [languageIso in LanguageNameType]: string }
 */
function createLocaleStructure(): LocalizedInput {
  return {
    en: '',
    cs: '',
    de: '',
    pl: '',
    sk: '',
    uk: '',
  };
}
