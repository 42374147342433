<script lang="ts" setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useSessionStore } from '~/store/session.store';
import Icon from 'goout-icons/Icon.vue';
import { User } from 'goout-schemas';
import { logout } from 'goout-auth';

const isOpen = ref(false);
const buttonRef = ref();
const sessionStore = useSessionStore();

onClickOutside(buttonRef, () => {
  isOpen.value = false;
});

async function selectContact(item: User['contacts'][0]) {
  sessionStore.selectedContact = item;
  isOpen.value = false;
}

const userImage = computed(() => sessionStore.user?.image);
const AVATAR_PLACEHOLDER =
  'https://storage.googleapis.com/goout-static/creator/AvatarPlaceholder.svg';
</script>

<template>
  <div class="relative">
    <div ref="buttonRef" tabindex="1" @click="isOpen = !isOpen">
      <div
        class="border-2 w-12.5 h-12.5 !p-4 rounded-full text-dark border-white cursor-pointer select-none bg-cover bg-center"
        :class="{
          'border-2 !border-blue ': isOpen,
          '!w-14.5 !h-14.5': !userImage,
        }"
        :style="{
          backgroundImage: `url(${userImage || AVATAR_PLACEHOLDER})`,
        }"
      ></div>
    </div>
    <div
      v-if="isOpen"
      class="dropdown bg-white px-7 py-2 absolute w-[272px] right-0 mt-12 top-2 z-10 cursor-default rounded-md"
    >
      <p class="text-gray pt-4 pb-3 text-sm">
        {{ sessionStore.user.firstName }} {{ sessionStore.user.lastName }}
      </p>
      <ul class="list-none">
        <li
          v-for="item in sessionStore.contacts"
          :key="item.id!"
          class="text-sm group text-dark cursor-pointer flex items-center gap-x-3 pt-4"
          :class="{
            'text-blue': sessionStore.selectedContact?.id === item.id,
          }"
          @click="selectContact(item)"
        >
          <Icon
            name="Avatar"
            class="w-12 h-12 text-gray-lighter group-hover:text-blue"
            :class="{
              '!text-blue': sessionStore.selectedContact?.id === item.id,
            }"
          />
          <span class="group-hover:text-blue">{{ item.name }}</span>
        </li>
      </ul>
      <div class="w-full h-[1px] mb-3 mt-4 bg-snow-white" />
      <p
        class="text-gray text-sm py-3 hover:text-gray/80 cursor-pointer"
        @click="async () => await logout()"
      >
        {{ $t('topbar.logout') }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background-image: url('https://storage.googleapis.com/goout-static/avatar.png');
  background-size: cover;
  background-position: center;
}

.dropdown {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
}
</style>
