<script lang="ts" setup>
import { DateSchema } from '~/definitions/schemas/date/date.schema';
import { Image } from '~/definitions/schemas/image/image.schema';
import { VenueTabState } from '~/types/form.types';
import { computed } from 'vue';
import ThumbnailSkeleton from './ThumbnailSkeleton.vue';
import { getDateTimeFormat } from '~/utils/dateTimeFormat.util';

const { date, name, image, primaryCategory, venue } = defineProps<{
  name: string;
  image: Image;
  primaryCategory: string | null;
  venue: VenueTabState['selectedItem'];
  date: DateSchema;
}>();

const dateFormatted = computed(() => {
  if (date.startTime.hour === '' && date.startTime.minute === '') return;
  const hour = date.startTime?.hour?.toString().padStart(2, '0') ?? '00';
  const minute = date.startTime?.minute?.toString().padStart(2, '0') ?? '00';
  const dateTime = `${date.startDate}T${hour}:${minute}`;
  const formatted = getDateTimeFormat(dateTime);
  // Make first letter uppercase
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
});
</script>

<template>
  <div class="flex flex-col w-64 text-pretty">
    <div class="shadow-md">
      <img
        v-if="image?.url"
        :src="image?.url"
        loading="eager"
        alt="event image"
        class="object-cover max-h-[160px] w-full"
      />
      <ThumbnailSkeleton v-else class="object-cover min-h-[160px] w-64" />
      <div class="flex flex-col p-8 gap-y-2">
        <h3 class="text-lg font-medium text-dark max-w-xs break-words">
          {{ name }}
        </h3>
        <p class="text-sm text-gray capitalize font-medium">
          {{ $t(`general.tags.${primaryCategory}`) }}
          <span v-if="dateFormatted">{{ dateFormatted }}</span>
        </p>
        <p class="text-sm text-gray font-medium leading-6">
          {{ venue?.title }}
        </p>
      </div>
    </div>
  </div>
</template>
