<script lang="ts" setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useSessionStore } from '~/store/session.store';
import Icon from 'goout-icons/Icon.vue';
import { User } from 'goout-schemas';

const isOpen = ref(false);
const buttonRef = ref();
const sessionStore = useSessionStore();

async function selectContact(item: User['contacts'][0]) {
  sessionStore.selectedContact = item;
  isOpen.value = false;
}

onClickOutside(buttonRef, () => {
  isOpen.value = false;
});

function toggleDropdown() {
  if (sessionStore.contacts.length <= 1) return;
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <div class="relative my-auto">
    <button
      ref="buttonRef"
      tabindex="0"
      class="flex items-center cursor-pointer p-1 select-none group"
      :class="{ 'cursor-auto': sessionStore.contacts.length < 2 }"
      :disabled="sessionStore.contacts.length <= 1"
      @click="toggleDropdown"
    >
      <h4
        class="text-sm text-dark font-medium group-hover:text-blue"
        tabindex="0"
        :aria-expanded="isOpen"
        aria-haspopup="true"
        aria-controls="locale-dropdown"
      >
        {{ sessionStore.selectedContact.name }}
      </h4>
      <Icon
        v-if="sessionStore.contacts.length > 1"
        name="Chevron"
        class="h-[24px] w-[24px] p-1.5 ml-2 text-blue"
        size="small"
        :class="{ 'transform rotate-180': isOpen }"
      />
    </button>
    <div
      v-if="isOpen"
      class="dropdown bg-white absolute w-[220px] left-0 mt-7 top-2 z-10 cursor-default rounded-md shadow-lg p-1"
      role="menu"
    >
      <ul class="list-none px-4 pt-1">
        <p class="text-gray pt-4 pb-2 text-sm">{{ $t('event.organizer') }}</p>
        <li
          v-for="item in sessionStore.contacts"
          :key="item.id!"
          class="text-sm group py-3 text-dark cursor-pointer flex items-center gap-3"
          :class="{
            'text-blue': sessionStore.selectedContact?.id === item.id,
          }"
          @click="selectContact(item)"
        >
          <Icon
            name="Avatar"
            class="w-12 h-12 text-gray-lighter group-hover:text-blue"
            :class="{
              '!text-blue': sessionStore.selectedContact?.id === item.id,
            }"
          />
          <span class="group-hover:text-blue truncate">{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
}
</style>
