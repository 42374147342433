<script lang="ts" setup>
import Modal from '~/components/general/Modal.vue';
import TextInput from '~/components/general/TextInput.vue';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EventBox from '~/components/events/EventBox.vue';
import { Schedule } from 'goout-schemas';
import { useDataStore } from '~/store/data.store';
import { z } from 'zod';
import { sendResponse, useForm } from '~/composables/useForm';
import TextArea from '~/components/general/TextArea.vue';
import { getCurrentLocale } from '~/i18n';
import { ModalRoute, useModal } from '~/composables/useModal';
import { useSessionStore } from '~/store/session.store';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import { useGooutFetch } from 'goout-api';

const route = useRoute();
const router = useRouter();
const dataStore = useDataStore();
const sessionStore = useSessionStore();

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'error.required' })
    .email('error.invalid_email'),
  message: z
    .string()
    .min(1, { message: 'error.required' })
    .max(2000, { message: 'error.max_length' }),
});

const email = ref<string>(sessionStore.user?.email || '');
const message = ref<string>('');
const { submit, errors, isLoading } = useForm({ schema });
const isOpen = ref<boolean>(true);
const { callback } = useModal();

const schedule = computed<Schedule | undefined>(() =>
  dataStore.getScheduleById(route.params.id as string)
);
const isBtnDisabled = computed(
  () => email.value === '' || message.value === '' || errors.value.length > 0
);

const handleSubmit = () => {
  callback.value = () =>
    onSubmit({ email: email.value, message: message.value });
  router.push({ name: ModalRoute.ConfirmModalEdit });
};

const handleClose = () => {
  isOpen.value = false;
  router.push({ name: 'Overview' });
};

const { onSubmit } = submit(schema, async ({ email, message }) => {
  const { statusCode } = await useGooutFetch(
    ENDPOINT.SUPPORT_NOTIFICATIONS
  ).post({
    contactEmail: email,
    description: message,
    language: getCurrentLocale(),
    eventId: route.params.id,
    requestKind: 'EVENT_EDIT',
  });

  return sendResponse(statusCode);
});

// We use watch since it can take a while for the user to be authenticated
watch(
  () => sessionStore.user?.email,
  () => {
    email.value = sessionStore.user?.email || '';
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <Modal
    :open="isOpen"
    class="px-10"
    rounded="3xl"
    :disabled="isBtnDisabled"
    :is-loading="isLoading"
    :on-close="handleClose"
    @submit="handleSubmit"
  >
    <template #title>
      {{ $t('event.edit.title') }}
    </template>

    <template #description>
      {{ $t('event.edit.description') }}
    </template>

    <template #content>
      <EventBox v-if="schedule" :schedule compact />
      <TextInput
        v-model="email"
        :schema="schema.shape.email"
        label="Email"
        class="mt-5"
      />

      <TextArea
        v-model="message"
        :schema="schema.shape.message"
        label="Message"
        class="mt-5"
        :rows="6"
      />
    </template>

    <template #confirm>
      <span>{{ $t('btn.send') }}</span>
    </template>
  </Modal>
</template>
~/store/session.store
