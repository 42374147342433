import { z } from 'zod';

const text = z.string().optional();
const localizedRequiredSchema = z
  .object({
    cs: text,
    en: text,
    sk: text,
    pl: text,
    de: text,
    uk: text,
  })
  .refine(
    (value) => {
      const values = Object.values(value);
      return values.some((val) => val.length >= 2 && val.length <= 64);
    },
    {
      message: 'error.name_too_short',
    }
  );

const localizedOptionalSchema = z.object({
  cs: z.string().optional(),
  en: z.string().optional(),
});

const urlRegex =
  /^(https?:\/\/)?([wW]{3}\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;

export const generalSchema = z.object({
  name: localizedRequiredSchema,
  description: localizedOptionalSchema,
  web: z
    .string()
    .trim()
    .regex(urlRegex, 'error.invalid_url')
    .optional()
    .or(z.literal(''))
    .optional(),
});

export type LocalizedInput = z.infer<typeof localizedRequiredSchema>;
