<script setup lang="ts">
import { ref } from 'vue';
import Button from 'goout-ui-kit/button/Button.vue';
import LogoButton from '~/components/general/LogoButton.vue';
import NavigationItem from '~/components/layout/NavigationItem.vue';
import useDetectOutsideClick from 'goout-ui-kit/composables/useDetectOutsideClick';
import FeedbackBox from '../general/FeedbackBox.vue';

const isSidebarOpen = ref(false);
function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value;
}

const componentRef = ref();
useDetectOutsideClick(componentRef, () => {
  isSidebarOpen.value = false;
});
</script>

<template>
  <nav
    ref="componentRef"
    class="w-full lg:w-58 lg:min-w-58 lg:border-r-1 lg:border-snow-white flex flex-col h-full -mt-24"
  >
    <header class="mx-6 mb-4 flex justify-between">
      <LogoButton class="pt-5 lg:my-3 lg:ml-4" />
      <Button
        :icon="isSidebarOpen ? 'Close' : 'MenuNew'"
        variant="text"
        class="lg:hidden text-dark border-l-1 border-snow-white !p-0 !pl-4 ml-4"
        @click="toggleSidebar"
      />
    </header>

    <div
      class="fixed w-full opacity-0 lg:relative lg:opacity-100 flex-grow overflow-y-auto"
      :class="{
        '!opacity-100 overflow-hidden bg-white': isSidebarOpen,
      }"
    >
      <ul>
        <NavigationItem is-active class="w-full">
          {{ $t('layout.mainPage.myEvents') }}
        </NavigationItem>
      </ul>
    </div>
    <FeedbackBox class="mt-auto mx-auto hidden lg:block" />
  </nav>
</template>
