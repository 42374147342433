<script lang="ts" setup>
import { SummaryItemProps } from './SummaryItem.vue';
import { LocalizedInput } from '~/definitions/schemas/general/general.schema';
import SummaryItem from './SummaryItem.vue';
import LanguageBadge from './LanguageBadge.vue';
import { onMounted, ref, computed } from 'vue';

type SummaryItemLocalizedProps = Pick<SummaryItemProps, 'label' | 'isHtml'> & {
  localizedText: LocalizedInput;
};

const props = defineProps<SummaryItemLocalizedProps>();

const MAX_LENGTH = 200; // Maximum number of characters to show initially
const showFullText = ref<{ [key: string]: boolean }>({});

const truncatedText = computed(() => {
  const result: { [key: string]: string } = {};
  Object.entries(props.localizedText).forEach(([locale, text]) => {
    if (typeof text === 'string' && text.length > MAX_LENGTH) {
      result[locale] = text.slice(0, MAX_LENGTH) + '...';
    } else {
      result[locale] = text;
    }
  });
  return result;
});

const toggleShowMore = (locale: string) => {
  showFullText.value[locale] = !showFullText.value[locale];
};

onMounted(() => {
  Object.keys(props.localizedText).forEach((locale) => {
    showFullText.value[locale] = false;
  });
});
</script>

<template>
  <SummaryItem :label="label">
    <div
      v-for="[locale, text] in Object.entries(localizedText)"
      :key="locale"
      class="flex-col"
    >
      <div v-if="locale && text" class="flex items-start gap-x-2 py-2">
        <LanguageBadge :text="locale" />
        <div class="text-pretty">
          <p v-if="isHtml">
            <span
              v-html="showFullText[locale] ? text : truncatedText[locale]"
            />
          </p>
          <p v-else>
            {{ showFullText[locale] ? text : truncatedText[locale] }}
          </p>
          <a
            v-if="typeof text === 'string' && text.length > MAX_LENGTH"
            href="#"
            class="underline"
            @click.prevent="toggleShowMore(locale)"
          >
            {{
              showFullText[locale]
                ? $t('layout.mainPage.showLess')
                : $t('layout.mainPage.showMore')
            }}
          </a>
        </div>
      </div>
    </div>
  </SummaryItem>
</template>

<style lang="scss" scoped></style>
