import { format, parse, parseISO } from 'date-fns';
import { cs, enUS } from 'date-fns/locale';
import { DateSchema } from '~/definitions/schemas/date/date.schema';
import { getCurrentLocale } from '~/i18n';

// Map of supported locales to date-fns locale objects
const localeMap = {
  en: enUS,
  cs: cs,
};

// Map of date formats for each locale
const dateFormats = {
  en: 'MM/dd/yyyy', // Slash format for English
  cs: 'dd.MM.yyyy', // Dot format for Czech
};

// Map of datetime formats for each locale
const dateTimeFormats = {
  en: 'MM/dd/yyyy p', // Slash format with time for English
  cs: 'dd. MM. yyyy p', // Dot format with time for Czech
};

// Helper function to get the current date-fns locale
function getDateFnsLocale() {
  const currentLocale = getCurrentLocale();
  return localeMap[currentLocale] || enUS; // Default to English if locale not found
}

// Helper function to get the date format based on locale
function getDateFormatForLocale() {
  const currentLocale = getCurrentLocale();
  return dateFormats[currentLocale] || dateFormats.en;
}

// Helper function to get the datetime format based on locale
function getDateTimeFormatForLocale() {
  const currentLocale = getCurrentLocale();
  return dateTimeFormats[currentLocale] || dateTimeFormats.cs;
}

export function getDateTimeFormat(date: string) {
  const parsedDate = parseISO(date);
  const dateTimeFormat = getDateTimeFormatForLocale();
  return format(parsedDate, dateTimeFormat, { locale: getDateFnsLocale() });
}

export function getDateFormat(date: string) {
  const parsedDate = parseISO(date);
  const dateFormat = getDateFormatForLocale();
  return format(parsedDate, dateFormat, { locale: getDateFnsLocale() });
}

/* Convert a string to valid date */
export function parseDate(
  date: DateSchema['startDate'],
  time: DateSchema['startTime']
): Date {
  const paddedTime = padTime(time);
  return parse(
    `${date} ${paddedTime.hour}:${paddedTime.minute}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  );
}

function padTime(time: DateSchema['startTime']) {
  return {
    hour: String(time.hour).padStart(2, '0'),
    minute: String(time.minute).padStart(2, '0'),
  };
}
