import z from 'zod';
import { CityDtoAttributesSchema } from '../../city/city.schema';
import {
  ContactDtoAttributesSchema,
  ContactDtoRelationshipsSchema,
} from '../../contact/contact.schema';
import {
  DealDtoAttributesSchema,
  DealDtoRelationshipsSchema,
} from '../../deal/deal.schema';
import {
  DiscountDtoAttributesSchema,
  DiscountDtoRelationshipsSchema,
} from '../../discount/discount.schema';
import { EntityType, EntityTypeEnumSchema } from '../../entityType.schema';
import {
  EventDtoAttributesSchema,
  EventDtoRelationshipsSchema,
} from '../../event/event.schema';
import { ImageDtoAttributesSchema } from '../../image/image.schema';
import {
  SaleDtoAttributesSchema,
  SaleDtoRelationshipsSchema,
} from '../../sale/sale.schema';
import {
  ScheduleDtoAttributesSchema,
  ScheduleDtoRelationshipsSchema,
} from '../../schedule/schedule.schema';
import {
  UserDtoAttributesSchema,
  UserDtoRelationshipsSchema,
} from '../../user/user.schema';
import {
  VenueDtoAttributesSchema,
  VenueDtoRelationshipsSchema,
} from '../../venue/venue.schema';
import {
  PerformerDtoAttributesSchema,
  PerformerDtoRelationshipsSchema,
} from '../../performer/performer.schema';

// Define the type for the schema mapper
type EntityTypeSchemaMapperType = {
  [K in EntityType]: z.ZodObject<any, any, any> | any;
};

//TODO Entites with z.object({}) are not yet implemented
//TODO missing parents, innerSchedules, duplicateSchedules, parentInnerSchedules e.g. need to differentiate betwen include types and entity types
export const EntityTypeSchemaAttributeMapper: EntityTypeSchemaMapperType = {
  [EntityTypeEnumSchema.enum.venues]: VenueDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.cities]: CityDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.images]: ImageDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.schedules]: ScheduleDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.users]: UserDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.sales]: SaleDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.events]: EventDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.deals]: DealDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.contacts]: ContactDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.contact_users]: ContactDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.discounts]: DiscountDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.performers]: PerformerDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.contact]: ContactDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.event]: EventDtoAttributesSchema,
  [EntityTypeEnumSchema.enum.venue]: VenueDtoAttributesSchema,

  // TODO There are no schemas finised for the following entities, feel free to add them based on BE Kotlin Data classes
  [EntityTypeEnumSchema.enum.guestlists]: z.object({}),
  [EntityTypeEnumSchema.enum.guestlist_deals]: z.object({}),
  [EntityTypeEnumSchema.enum.guestlist_users]: z.object({}),
  [EntityTypeEnumSchema.enum.guestlist_purchases]: z.object({}),
  [EntityTypeEnumSchema.enum.halls]: z.object({}),
  [EntityTypeEnumSchema.enum.hall_blocks]: z.object({}),
  [EntityTypeEnumSchema.enum.hall_symbols]: z.object({}),
  [EntityTypeEnumSchema.enum.check_ins]: z.object({}),
  [EntityTypeEnumSchema.enum.purchases]: z.object({}),
  [EntityTypeEnumSchema.enum.purchase_transactions]: z.object({}),
  [EntityTypeEnumSchema.enum.seats]: z.object({}),
  [EntityTypeEnumSchema.enum.seat_tickets]: z.object({}),
  [EntityTypeEnumSchema.enum.tickets]: z.object({}),
  [EntityTypeEnumSchema.enum.videos]: z.object({}),
  [EntityTypeEnumSchema.enum.hall_logs]: z.object({}),
  [EntityTypeEnumSchema.enum.image]: z.object({}),
  [EntityTypeEnumSchema.enum.parents]: z.object({}),
  [EntityTypeEnumSchema.enum.related]: z.object({}),
};

export const EntityTypeSchemaRelationshipMapper: EntityTypeSchemaMapperType = {
  [EntityTypeEnumSchema.enum.venues]: VenueDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.cities]: z.object({}),
  [EntityTypeEnumSchema.enum.images]: z.object({}),
  [EntityTypeEnumSchema.enum.schedules]: ScheduleDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.users]: UserDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.sales]: SaleDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.events]: EventDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.deals]: DealDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.contacts]: ContactDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.contact_users]: ContactDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.discounts]: DiscountDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.performers]: PerformerDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.contact]: ContactDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.event]: EventDtoRelationshipsSchema,
  [EntityTypeEnumSchema.enum.venue]: VenueDtoRelationshipsSchema,

  // TODO There are no schemas finised for the following entities, feel free to add them based on BE Kotlin Data classes 
  [EntityTypeEnumSchema.enum.guestlists]: z.object({}),
  [EntityTypeEnumSchema.enum.guestlist_deals]: z.object({}),
  [EntityTypeEnumSchema.enum.guestlist_users]: z.object({}),
  [EntityTypeEnumSchema.enum.guestlist_purchases]: z.object({}),
  [EntityTypeEnumSchema.enum.halls]: z.object({}),
  [EntityTypeEnumSchema.enum.hall_blocks]: z.object({}),
  [EntityTypeEnumSchema.enum.hall_symbols]: z.object({}),
  [EntityTypeEnumSchema.enum.check_ins]: z.object({}),
  [EntityTypeEnumSchema.enum.purchases]: z.object({}),
  [EntityTypeEnumSchema.enum.purchase_transactions]: z.object({}),
  [EntityTypeEnumSchema.enum.seats]: z.object({}),
  [EntityTypeEnumSchema.enum.seat_tickets]: z.object({}),
  [EntityTypeEnumSchema.enum.tickets]: z.object({}),
  [EntityTypeEnumSchema.enum.videos]: z.object({}),
  [EntityTypeEnumSchema.enum.hall_logs]: z.object({}),
  [EntityTypeEnumSchema.enum.image]: z.object({}),
  [EntityTypeEnumSchema.enum.parents]: z.object({}),
  [EntityTypeEnumSchema.enum.related]: z.object({}),
};
