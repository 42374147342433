<script setup lang="ts">
import { Schedule } from 'goout-schemas';
import EventBoxSkeleton from '~/components/events/EventBoxSkeleton.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import Icon from 'goout-icons/Icon.vue';
import { computed, ref, nextTick } from 'vue';
import { useElementBounding } from '@vueuse/core';
import EventBoxListItem from './EventBoxListItem.vue';
import EventBox from './EventBox.vue';

interface Props {
  schedules: Schedule[];
  title: string;
  isLoading: boolean;
}

const { schedules, title, isLoading } = defineProps<Props>();

const INITIAL_ITEMS_COUNT = 3;
const itemsVisible = ref(INITIAL_ITEMS_COUNT);
const containerRef = ref<HTMLElement | null>(null);

const visibleSchedules = computed(() => schedules.slice(0, itemsVisible.value));

const hiddenSchedulesCount = computed(
  () => schedules.length - itemsVisible.value
);

const toggleItems = () => {
  if (itemsVisible.value < schedules.length) {
    itemsVisible.value = schedules.length;
  } else {
    itemsVisible.value = INITIAL_ITEMS_COUNT;
  }

  if (containerRef.value) {
    const { top } = useElementBounding(containerRef.value);
    nextTick(() => {
      window.scrollTo({ top: top.value, behavior: 'smooth' });
    });
  }
};
</script>

<template>
  <section ref="containerRef">
    <p v-if="schedules.length > 0" class="mb-2 text-sm text-gray-dark">
      {{ title }}
    </p>

    <div v-if="!isLoading">
      <table
        class="bg-white w-full border-collapse table-fixed hidden md:table"
      >
        <EventBoxListItem
          v-for="schedule in visibleSchedules"
          :key="schedule.id"
          :schedule
        />
      </table>

      <div class="flex flex-col gap-y-4 md:hidden">
        <EventBox
          v-for="schedule in visibleSchedules"
          :key="schedule.id"
          :schedule
        />
      </div>
    </div>

    <div v-else>
      <EventBoxSkeleton
        v-for="index in INITIAL_ITEMS_COUNT"
        :key="index"
        :compact="true"
        class="mb-4"
      />
    </div>

    <Button
      v-if="schedules.length > INITIAL_ITEMS_COUNT && !isLoading"
      variant="text"
      class="w-full pt-14"
      @click="toggleItems"
    >
      <span class="text-dark">
        {{
          itemsVisible < schedules.length
            ? $t('layout.mainPage.showMore')
            : $t('layout.mainPage.showLess')
        }}
      </span>
      <span v-if="hiddenSchedulesCount > 0" class="pl-1">
        {{ `(${hiddenSchedulesCount})` }}
      </span>
      <Icon
        class="ml-2 mt-[2.5px] !w-2.5 !h-3 text-blue transform"
        :class="{ 'rotate-180': itemsVisible >= schedules.length }"
        name="Chevron"
      />
    </Button>
  </section>
</template>
