import { createI18n } from 'vue-i18n';
import cs from '../locales/cs.json';
import en from '../locales/en.json';
import { nextTick } from 'vue';
import { useCookie } from 'goout-utils/cookie';
import { showKeyForMissingTranslations } from 'goout-utils/i18n';

export const SUPPORTED_LOCALES = ['cs', 'en'] as const;
export const FALLBACK_LOCALE = 'en';
export type SupportedLocale = typeof SUPPORTED_LOCALES[number];

function getValidLocale(locale: string): SupportedLocale {
  return SUPPORTED_LOCALES.includes(locale as SupportedLocale)
    ? (locale as SupportedLocale)
    : FALLBACK_LOCALE;
}

const locale: SupportedLocale = getValidLocale(
  useCookie().get('language') || navigator.language || FALLBACK_LOCALE
);

export const i18n = createI18n({
  locale,
  fallbackLocale: FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: {
    en,
    cs,
  },
});

export function setI18nLanguage(locale: SupportedLocale) {
  const validLocale = getValidLocale(locale);
  i18n.global.locale.value = validLocale;
  useCookie().set('language', validLocale);
  document.querySelector('html')?.setAttribute('lang', validLocale);
}

export function getCurrentLocale(): SupportedLocale {
  return i18n.global.locale.value as SupportedLocale;
}

export async function loadLocaleMessages(locale: SupportedLocale) {
  const messages = await import(`../locales/${locale}.json`);
  i18n.global.setLocaleMessage(locale, messages.default);
  return nextTick();
}

export async function setupI18n() {
  showKeyForMissingTranslations(i18n as any);
  setI18nLanguage(i18n.global.locale.value as SupportedLocale);
  await loadLocaleMessages(i18n.global.locale.value as SupportedLocale);
}
