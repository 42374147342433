<script setup lang="ts">
import Wizard, { Tab } from 'goout-ui-kit/wizard/Wizard.vue';
import { useTabsStore } from '~/store/tabs.store';

import { useRouter } from 'vue-router';
import { useFormStore } from '~/store/form.store';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import Spinner from '~/components/general/Spinner.vue';
import { useTracking } from '~/composables/useTracking';
import Button from 'goout-ui-kit/button/Button.vue';
import SchedulePublishModal from '~/components/modals/SchedulePublishModal.vue';

const tabsStore = useTabsStore();
const { currentComponent, currentTab } = storeToRefs(tabsStore);
const formStore = useFormStore();
const isScheduleModalOpen = ref(false);

const router = useRouter();
const { trackClick } = useTracking();

const handleBackButtonClick = () => {
  trackClick({
    category: 'redirect_button',
    label: 'Back',
    type: 'button_click',
  });

  tabsStore.currentTab === 'general'
    ? router.push({ name: 'Overview' })
    : tabsStore.goToPreviousTab();
};

const handleNextButtonClick = () => {
  trackClick({
    category: 'redirect_button',
    label: isLastTab.value ? 'Publish' : 'Next',
    type: 'button_click',
  });

  tabsStore.goToNextTab();
};

const tabs = computed(() =>
  [...tabsStore.tabs].map(([key, value]) => ({
    id: key,
    order: value.order,
    title: value.title,
    component: value.component,
    isValid: formStore[key]?.isValid,
    isVisited: formStore[key]?.isVisited,
    isDirty: formStore[key]?.isDirty,
  }))
);

const currentTabState = computed(() => formStore[currentTab.value]);

const handleTabClick = (tab: Tab) => {
  trackClick({
    category: 'flow_button',
    label: tab.id,
    type: 'button_click',
  });

  // If the tab is not valid, do not allow the user to navigate elsewhere
  if (!currentTabState.value?.isValid && currentTabState.value?.isDirty) {
    return;
  }

  if (tab.isVisited) router.push({ params: { step: tab.id } });
};

const handlePublishButtonClick = () => {
  trackClick({
    category: 'redirect_button',
    label: 'Publish',
    type: 'button_click',
  });

  formStore.submit();
};

const handleSchedulePublishingButtonClick = () => {
  trackClick({
    category: 'redirect_button',
    label: 'Schedule Publishing',
    type: 'button_click',
  });

  formStore.submit();
};

const isLastTab = computed(() => tabsStore.currentTab === 'publish');
</script>

<template class="relative min-h-screen">
  <form>
    <component
      :is="currentComponent"
      :id="currentTab"
      :aria-labelledby="'tab-' + currentTab"
      role="tabpanel"
      class="pb-30"
    />
    <Wizard
      class="z-20"
      :tabs
      :current-tab="tabsStore.currentTab"
      :next-button-disabled="!formStore.isCurrentTabDataValid"
      :is-last-tab="isLastTab"
      @publish="handlePublishButtonClick"
      @on-back-button="handleBackButtonClick"
      @on-next-button="handleNextButtonClick"
      @on-tab-click="handleTabClick"
    >
      <template #additionalButton>
        <Spinner v-if="formStore.isSubmitting" class="!text-white text-blue" />
        <Button
          v-else-if="isLastTab"
          variant="text-blue"
          size="lg"
          @click="isScheduleModalOpen = true"
        >
          {{ $t('wizard.schedule.publishing') }}
        </Button>
      </template>
      <template #publishButton>
        <Spinner v-if="formStore.isSubmitting" class="!text-white text-blue" />
        <span v-else> {{ $t('wizard.publish') }}</span>
      </template>
      <template #nextButton>
        {{ $t('wizard.next') }}
      </template>

      <template #backButton>
        {{ $t('wizard.back') }}
      </template>
    </Wizard>
    <SchedulePublishModal
      v-if="isScheduleModalOpen"
      v-model="isScheduleModalOpen"
      @submit="handleSchedulePublishingButtonClick"
    />
  </form>
</template>
