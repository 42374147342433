<script lang="ts" setup>
export type SummaryItemProps = {
  label: string;
  text?: string;
  isHtml?: boolean;
};

const { text, isHtml = false } = defineProps<SummaryItemProps>();
// Script escaping
const textContext = isHtml ? text : text;
</script>

<template>
  <div class="flex flex-col gap-y-1 pt-6">
    <p class="text-[11px] font-medium text-gray">{{ label }}</p>
    <div class="text-sm font-medium text-dark !max-w-100 pr-5 text-pretty">
      <slot />
      <div v-if="isHtml">
        <span v-html="textContext"></span>
      </div>
      <p v-else>
        <span v-if="textContext">{{ textContext }}</span>
        <slot v-else />
      </p>
    </div>
  </div>
  <div class="border-gray-light border-b-1 pb-7 w-full max-w-full"></div>
</template>
