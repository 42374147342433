import { z } from 'zod';
import { EntityTypeEnumSchema } from '../../entityType.schema';

export const HAS_ONE = 'hasOne';
export const HAS_MANY = 'hasMany';

type DevourAttribute = string | number | null | [] | {
  jsonApi: typeof HAS_ONE | typeof HAS_MANY;
  type: string;
} | Record<string, any>;

export function convertZodFieldToDevourAttribute(zodField: z.ZodTypeAny): DevourAttribute {
  if (zodField instanceof z.ZodEnum) {
    return '';
  } else if (zodField instanceof z.ZodString) {
    return '';
  } else if (zodField instanceof z.ZodNumber) {
    return 0;
  } else if (
    zodField.description === 'one' &&
    zodField instanceof z.ZodOptional
  ) {
    const unwrappedSchema = zodField.unwrap();
    const type = EntityTypeEnumSchema.parse(
      unwrappedSchema['_def']['innerType']['_def']
        .shape()
        ['data']['_def'].shape()['type']['_def']['value']
    );
    return {
      jsonApi: HAS_ONE,
      type,
    };
  } else if (zodField.description === 'many') {
    const type = EntityTypeEnumSchema.parse(
      zodField['_def'].shape().data['_def']?.type?._def?.shape()?.type?._def?.value
    );
    return {
      jsonApi: HAS_MANY,
      type,
    };
  } else if (zodField instanceof z.ZodArray) {
    const itemType = zodField.element;
    if (itemType instanceof z.ZodEnum || itemType instanceof z.ZodString) {
      return [];
    }
  } else if (zodField instanceof z.ZodObject) {
    return convertZodToDevourModel(zodField);
  } else if (zodField instanceof z.ZodUnion) {
    // Handle optional fields
    return zodField.options.some((option) => option instanceof z.ZodUndefined)
      ? null
      : '';
  }

  return null; // Fallback for unhandled types
}

type DevourAttributes<T> = {
  [K in keyof T]: DevourAttribute;
};

/**
 * Converts a Zod schema to a Devour model
 * @param zodSchema
 * @returns Devour model
 */
export function convertZodToDevourModel<T extends z.ZodObject<any>>(
  zodSchema: T
): DevourAttributes<T['shape']> {
  const devourAttributes: Partial<DevourAttributes<T['shape']>> = {};

  Object.keys(zodSchema.shape).forEach((key) => {
    const devourAttribute = convertZodFieldToDevourAttribute(
      zodSchema.shape[key]
    );

    devourAttributes[key as keyof T['shape']] = devourAttribute;

    if (devourAttribute && typeof devourAttribute === 'object' && 'data' in devourAttribute) {
      devourAttributes[key as keyof T['shape']] = devourAttribute.data;
    }
  });

  return devourAttributes as DevourAttributes<T['shape']>;
}
