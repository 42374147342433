<script lang="ts" setup>
import { computed, ref, nextTick } from 'vue';
import * as z from 'zod';
import { onClickOutside } from '@vueuse/core';
import { DateSchema } from '~/definitions/schemas/date/date.schema';

export interface TextInputProps {
  label: string;
  required?: boolean;
  errors?: z.ZodIssue[];
  disabled?: boolean;
  lowerLimit?: DateSchema['endTime'];
  upperLimit?: DateSchema['startTime'];
  forceLimit?: boolean;
}

const {
  disabled = false,
  lowerLimit,
  upperLimit,
  forceLimit = false,
} = defineProps<TextInputProps>();

const timepicker = ref<HTMLDivElement | null>(null);
const model = defineModel<{
  hour: number | '' | string;
  minute: number | '' | string;
}>({
  default: {
    hour: 0,
    minute: 0,
  },
});

onClickOutside(timepicker, () => (isOpen.value = false));

const isOpen = ref(false);
const hourRefs = ref<HTMLElement[]>([]);
const minuteRefs = ref<HTMLElement[]>([]);
const paddingRef = ref<HTMLElement | null>(null);

/**
 * This computed property generates an array of hours (0 to 23).
 * It takes into account the lower and upper time limits to filter the hours accordingly.
 *
 * @returns {number[]} Array of filtered hours.
 */
const hourInterval = computed(() => {
  const hours = Array.from({ length: 24 }, (_, i) => i);

  if (!forceLimit) {
    return hours;
  }

  let filteredHours = hours;

  if (lowerLimit && (lowerLimit.hour !== '' || lowerLimit.minute !== '')) {
    filteredHours = filteredHours.filter(
      (hour) =>
        (lowerLimit.hour && hour > +lowerLimit.hour) ||
        (hour === lowerLimit.hour && +model.value.minute >= +lowerLimit.minute!)
    );
  }

  if (upperLimit && (upperLimit.hour !== '' || upperLimit.minute !== '')) {
    filteredHours = filteredHours.filter(
      (hour) =>
        (upperLimit.hour && hour < +upperLimit.hour) ||
        (hour === upperLimit.hour && +model.value.minute <= +upperLimit.minute)
    );
  }

  return filteredHours;
});

/**
 * This computed property generates an array of minutes in 5-minute intervals (0, 5, 10, ..., 55).
 * It takes into account the lower and upper time limits to filter the minutes accordingly.
 *
 * @returns {number[]} Array of filtered minutes.
 */
const minuteInterval = computed(() => {
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5);

  if (!forceLimit) {
    return minutes;
  }

  let filteredMinutes = minutes;

  if (model.value.hour === lowerLimit?.hour) {
    filteredMinutes = filteredMinutes.filter(
      (minute) => minute >= +lowerLimit.minute
    );
  }

  if (model.value.hour === upperLimit?.hour) {
    filteredMinutes = filteredMinutes.filter(
      (minute) => minute <= +upperLimit.minute
    );
  }

  return filteredMinutes;
});

const hourValue = computed(() =>
  model.value?.hour || model.value?.hour === 0
    ? model.value?.hour.toString().padStart(2, '0')
    : ''
);
const minuteValue = computed(() =>
  model.value?.minute || model.value?.minute === 0
    ? model.value?.minute.toString().padStart(2, '0')
    : ''
);

const hasValue = computed(
  () =>
    model.value?.hour ||
    model.value?.hour === 0 ||
    model.value?.minute ||
    model.value?.minute === 0
);

const wrapper = ref<HTMLElement>();

function handleHourClick(behavior: 'smooth' | 'instant' = 'smooth') {
  if (String(model.value.hour) === '' && String(model.value.minute) === '') {
    hourRefs.value[2].scrollIntoView({
      behavior: 'instant',
      block: 'center',
    });
    return;
  }

  nextTick(() => {
    const hourIndex = hourInterval.value.indexOf(+model.value.hour);
    if (hourIndex !== -1 && hourRefs.value[hourIndex]) {
      hourRefs.value[hourIndex].scrollIntoView({
        behavior,
        block: 'center',
      });
    }
  });
}

function handleMinuteClick(behavior: 'smooth' | 'instant' = 'smooth') {
  if (String(model.value.hour) === '' && String(model.value.minute) === '') {
    minuteRefs.value[2].scrollIntoView({
      behavior: 'instant',
      block: 'center',
    });
    return;
  }

  nextTick(() => {
    const minuteIndex = minuteInterval.value.indexOf(+model.value.minute);
    if (minuteIndex !== -1 && minuteRefs.value[minuteIndex]) {
      nextTick(() => {
        minuteRefs.value[minuteIndex].scrollIntoView({
          behavior,
          block: 'center',
        });
      });
    }
  });
}
</script>

<template>
  <div ref="timepicker">
    <label ref="wrapper" class="text-sm relative block h-full">
      <div
        class="bg-snow-white outline-none text-dark font-medium px-4 pt-7 pb-4 transition-all focus:shadow-border focus:shadow-blue focus:bg-gray-50 hover:bg-gray/20 disabled:bg-snow-white"
        :aria-label="label"
        :disabled
        type="number"
        tabindex="0"
        @focus="
          isOpen = true;
          nextTick(() => handleHourClick('instant'));
          nextTick(() => handleMinuteClick('instant'));
        "
      >
        <div class="flex pointer-events-none font-medium">
          <input
            class="text-gray-lighter !max-w-6"
            type="text"
            maxlength="2"
            :value="hourValue"
            disabled
          />
          <span v-if="hasValue" class="pr-1">:</span>
          <input
            class="text-gray-lighter pointer-events-none font-medium"
            type="text"
            :value="minuteValue"
            maxlength="2"
            disabled
          />
        </div>
      </div>

      <span
        class="text-gray text-sm font-medium transform transition-all top-1/2 -translate-y-1/2 left-4 absolute pointer-events-none"
        :class="{ 'has-value': hasValue }"
      >
        {{ label }}
      </span>

      <div
        v-show="isOpen"
        id="minute"
        class="absolute h-40 w-full shadow-md bg-white pt-4 z-20"
      >
        <div class="flex gap-x-2">
          <div ref="hourScroll" class="flex flex-col max-h-36 overflow-scroll">
            <div ref="paddingRef" class="w-full pb-[60px]"></div>
            <div
              v-for="hour in hourInterval"
              ref="hourRefs"
              :key="hour"
              class="px-4 py-1 hover:text-blue cursor-pointer text-gray font-medium"
              :class="{ 'text-dark font-semibold': hour === model.hour }"
              @click="
                model.hour = hour;
                nextTick(() => handleHourClick());
              "
            >
              <span>{{ hour.toString().padStart(2, '0') }}</span>
            </div>
            <div class="w-full pb-[60px]"></div>
          </div>
          <div class="flex flex-col justify-center">
            <span class="font-medium">:</span>
          </div>
          <div class="flex flex-col max-h-36 overflow-scroll">
            <div ref="paddingRef" class="w-full pb-[60px]"></div>
            <div
              v-for="minute in minuteInterval"
              ref="minuteRefs"
              :key="minute"
              class="px-4 py-1 hover:text-blue cursor-pointer text-gray font-medium"
              :class="{ 'text-dark font-semibold': minute === model.minute }"
              @click="
                model.minute = minute;
                nextTick(() => handleMinuteClick());
              "
            >
              <span>{{ minute.toString().padStart(2, '0') }}</span>
            </div>
            <div class="w-full pb-[60px]"></div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.has-value {
  transform: translateY(-110%);
  font-size: 11px;
}

label:focus-within > .text-gray {
  transform: translateY(-110%);
  font-size: 11px;
}
input {
  all: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* hide scrollbar */
::-webkit-scrollbar {
  display: none;
}
</style>
